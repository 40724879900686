import React from "react";
import "./Hero.css";
import { setAttributes, RelativeLink } from "../../utils";

import { GatsbyImage } from "gatsby-plugin-image";

const Hero = ({
  image,
  title,
  subtitle,
  subtitleAfter,
  callToAction,
  callToAction2,
  size,
  backgroundPosition,
  heroType,
  overlayBoxWidth,
  advancedSettings,
}) => {
  const defaultClass = "section hero hero__" + size;
  let attributes = setAttributes(defaultClass, advancedSettings);

  const titleTag = React.createElement(`${title.htmlTag}`, {
    className: "hero__title",
    dangerouslySetInnerHTML: {
      __html: title.text,
    },
  });
  const subtitleTag = React.createElement(`${subtitle.htmlTag}`, {
    className: "hero__subtitle",
    dangerouslySetInnerHTML: {
      __html: subtitle.text,
    },
  });
  const subtitleAfterTag = React.createElement(`${subtitleAfter.htmlTag}`, {
    className: "hero__subtitle_after",
    dangerouslySetInnerHTML: {
      __html: subtitleAfter.text,
    },
  });

  let CTA = null;
  if (callToAction.text !== null) {
    CTA = (
      <RelativeLink
        link={callToAction.link}
        classNames="hero__cta button center"
      >
        {callToAction.text}{" "}
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
          <line x1="16" y1="2" x2="16" y2="6"></line>
          <line x1="8" y1="2" x2="8" y2="6"></line>
          <line x1="3" y1="10" x2="21" y2="10"></line>
        </svg>
      </RelativeLink>
    );
  }

  let CTA2 = null;
  if (callToAction2.text !== null) {
    CTA2 = (
      <RelativeLink
        link={callToAction2.link}
        classNames="hero__cta button center"
      >
        {callToAction2.text}{" "}
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
          <line x1="16" y1="2" x2="16" y2="6"></line>
          <line x1="8" y1="2" x2="8" y2="6"></line>
          <line x1="3" y1="10" x2="21" y2="10"></line>
        </svg>
      </RelativeLink>
    );
  }

  return (
    <section
      id={attributes.id}
      aria-label={attributes.ariaLabel}
      className={attributes.className}
      data-settings={attributes.settings}
    >
      <GatsbyImage
        image={image.imageFile.childImageSharp.gatsbyImageData}
        className={"section__background"}
        alt={image.altText}
        title={image.title}
        width={1920}
        height={1080}
        imgStyle={{ objectPosition: backgroundPosition }}
        objectPosition={backgroundPosition}
        loading={"eager"}
      />
      <div
        className={[
          "container container-fluid",
          attributes.containerClasses,
        ].join(" ")}
      >
        <div className="row center-xs">
          <div
            className={`col-xs-12 ${attributes.containerClasses === "full-col" || heroType === "bkgd" ? "col-sm-12" : "col-sm-10"
            }${size === "xl" && heroType === "default" ? " col-md-10" : ""}`}
          >
            <div className={`box${heroType === "bkgd" ? " color-bkgd " + overlayBoxWidth : ""}`}>
              {title.text !== null ? titleTag : null}
              {subtitle.text !== null ? subtitleTag : null}
              {CTA}
              {CTA2}
              {subtitleAfter.text !== null ? subtitleAfterTag : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
